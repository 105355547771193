import axios from 'axios'
import qs from 'qs'

import * as _ from '../util/tool'

// axios 配置
axios.defaults.timeout = 5000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
axios.defaults.baseURL = process.env.VUE_APP_BASE_API;

//POST传参序列化
axios.interceptors.request.use((config) => {
    if(config.method  === 'post'){
        config.data = qs.stringify(config.data);
    }
    return config;
},(error) =>{
    _.toast("错误的传参", 'fail');
    return Promise.reject(error);
});

//返回状态判断
axios.interceptors.response.use((res) =>{
    if(!res.data.code === 1){
        // _.toast(res.data.msg);
        return Promise.reject(res);
    }
    return res;
}, (error) => {
    _.toast("网络异常", 'fail');
    return Promise.reject(error);
});

export function fetch(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}

export function fetchs(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, params)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })
}
export default {
    /**
     * 保存推荐信息
     */
    getList(params) {
        return fetchs( '/sidebar/gift/index',{ params })
    },
    /**
     * 保存推荐信息
     */
    saveGift(params) {
        return fetchs( '/sidebar/gift/submit',{ params })
    },
    /**
     * 提交生日抽奖报名
     */
    birthdaySubmit(params) {
        return fetch('/sidebar/birthday/submit', params);
    },
    /**
     * 获取生日抽奖数据
     */
    getBirthdayData(params) {
        return fetchs( '/sidebar/birthday/read',{ params })
    }
}
